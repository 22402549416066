import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideAuth0 } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { authInteceptorFn } from 'src/interceptors/auth.interceptor';
import { EnvironmentService } from 'src/services/environment.service';
import { GlobalErrorHandler } from 'src/services/global-error-handler.service';
import { GoogleTagManagerService } from 'src/services/google-tag-manager.service';
import { routes } from './app.router';

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withInterceptors([authInteceptorFn])),
        provideRouter(routes),
        EnvironmentService,
        provideAuth0({
            domain: environment.origins.auth0Login,
            clientId: environment.auth0.clientId,
            authorizationParams: {
                redirect_uri: `${window.location.origin}/${window.location.search}`,
                audience: 'https://bannerflow.com/resources/',
                scope: 'openid fontmanager'
            },
            httpInterceptor: {
                allowedList: [`${environment.origins.fontManagerApi}/*`]
            },
            useRefreshTokens: true
        }),
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        GoogleTagManagerService,
        provideAnimations()
    ]
};
