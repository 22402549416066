import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UIModule, UIThemeService } from '@bannerflow/ui';
import { FontService } from 'src/services';
import { GoogleTagManagerService } from 'src/services/google-tag-manager.service';
import { TrackerService } from 'src/services/tracker.service';

@Component({
    imports: [CommonModule, UIModule, RouterOutlet],
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    private fontService = inject(FontService);
    private googleTagManagerService = inject(GoogleTagManagerService); // Make sure GTM is initialized
    private trackerService = inject(TrackerService);

    get loading(): boolean {
        return this.fontService.isLoading;
    }

    constructor() {
        inject(UIThemeService); // To make sure that the themes are read from the URL, we need to import themeService somewhere. No need for any actions though.
        this.trackerService.initNewRelic();

        this.googleTagManagerService.initializeGtm();
    }
}
